@tailwind base;
@tailwind components;
@tailwind utilities;
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700;800;900&display=swap");

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .stepper {
    position: relative;
    border-radius: 5px;
    flex: 100%;
    /* width: 370px; */
    padding: 20px 20px 20px 40px;
  }

  .last {
    /* height: 78px; */
    -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .first {
    -webkit-clip-path: polygon(
      0% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      0% 100%
    );
  }

  .center3 {
    position: absolute;
    height: 78px;
    clip-path: polygon(
      18.5px 50%,
      -0.5% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      -0.5% 100%
    );
  }

  @keyframes marqueeRightToLeft {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }

  @keyframes marqueeLeftToRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .animate-marquee {
    animation: marqueeLeftToRight 25s linear infinite alternate;
  }

  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hide-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

@layer components {
  .scrollbar-x::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .scrollbar-x::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: none;
    margin: 1px;
  }

  .scrollbar-x::-webkit-scrollbar-thumb:hover {
    background: #f18966;
  }
}

@media (max-width: 640px) {
  .mobile-scroll {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}

.semi-circle {
  --height: 15px;

  height: calc(2 * var(--height));
  width: calc(4 * var(--height));
  border-radius: calc(5 * var(--height)) calc(5 * var(--height)) 0 0;
  background: #f7f6f3;
  transform: rotate(180deg);
}

.card-container {
  perspective: 1000px;
}

.card {
  width: 270px;
  height: 405px;
}

.card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 1s ease-in-out;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-face {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.card-face img {
  width: 100%;
  height: 100%;
}

.card-face:nth-child(2) {
  transform: rotateY(180deg);
}

.stepper {
  position: relative;
  border-radius: 5px;
  flex: 100%;
  /* width: 370px; */
  padding: 20px 20px 20px 40px;
}

.last {
  /* height: 78px; */
  -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.first {
  -webkit-clip-path: polygon(
    0% 0%,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    0% 100%
  );
}

.center3 {
  position: absolute;
  height: 78px;
  clip-path: polygon(
    18.5px 50%,
    -0.5% 0%,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    -0.5% 100%
  );
}

.center {
  position: relative;
  clip-path: polygon(
    20px 50%,
    0% 0%,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    0% 100%
  );
}

.text-stroke {
  -webkit-text-stroke: 6px white;
}

@font-face {
  font-family: "montserrat";
  src: url("../public/assets/fonts/Montserrat-VariableFont_wght.ttf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "openSans";
  src: url("../public/assets/fonts/OpenSans-VariableFont_wdth.ttf")
    format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SemiBold";
  src: url("../public/assets/fonts/Syne-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Bold700";
  src: url("../public/assets/fonts/Syne-ExtraBold.ttf") format("truetype");
  font-display: swap;
}

.slider .thumb {
  appearance: none;
  width: 15px;
  height: 15px;
  background: #4bb9aa;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -5px;
  outline: none;
}

.track.track-0 {
  height: 5px;
  border-radius: 4px;
  background: #737373;
  opacity: 0.5;
}

.track.track-1 {
  height: 5px;
  border-radius: 4px;
  background: #4bb9aa;
}

.track.track-2 {
  height: 5px;
  border-radius: 4px;
  background: #737373;
  opacity: 0.5;
}

.slider .mark {
  background-color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-input {
  border-radius: 4px;
  width: 564px;
  display: flex;
  height: 44px;
  justify-content: flex-end;
  align-items: center;
  background: #7373731a;
}

.custom-input:focus {
  outline: none;
  border: 1px solid #0047ab;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  border-color: #ffffff;
  vertical-align: middle;
}

input[type="radio"]:checked {
  border-color: #4bb9aa;
}

input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: white;
  background-color: #4bb9aa;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 768px) {
  input[type="radio"] {
    width: 16px;
    height: 16px;
  }

  input[type="radio"]:checked::before {
    width: 8px;
    height: 8px;
  }
}
input[type="checkbox"]:checked {
  background-color: #50b848;
  border: none !important;
}
input[type="checkbox"]:checked:after {
  content: "\2713";
  color: white;
}
input[type="checkbox"] {
  text-align: center;
  vertical-align: middle;
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
  appearance: none;
  border-radius: 4px;
  border: 1px solid white;
  box-shadow: none;
  font-size: 12px;
}
